<template>
  <div>
    <!-- <navBar :title="title"></navBar> -->

    <div class="logo">
      <img alt src="../../../assets/images/1791637026300_.pic_hd.jpg" />
    </div>

    <div class="user">
      <span v-noLimit="{action:tokenStatus}">请先登录</span>
      <span class="name" v-permission="{action:tokenStatus}">Hello {{userName}}</span>
      <van-icon @click="loginOut" name="cross" v-permission="{action:tokenStatus}" />
    </div>

    <ul class="menu">
      <router-link to="/historyPage">
        <li>
          我的订单
          <van-icon color="#ccc" name="arrow" />
        </li>
      </router-link>
      <li @click="jump('bill')">
        发票信息
        <van-icon color="#ccc" name="arrow" />
      </li>
      <li @click="jump('mail')">
        邮寄信息
        <van-icon color="#ccc" name="arrow" />
      </li>
      <router-link to="/managementPage">
        <li>
          <router-link to="/managementPage">账号管理</router-link>
          <van-icon color="#ccc" name="arrow" />
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import navBar from '../navBar'
import { Dialog } from 'vant';

export default {
  name: 'owner',
  data () {
    return {
      title: {
        name: '我的',
        status: true,
      },
      userName: ''
    }
  },
  components: {
    navBar
  },
  created () {

    if (sessionStorage.getItem('user-token')) {
      this.userName = JSON.parse(sessionStorage.getItem('user-token')).enterpriseUserDTO.userName;
    }
  },
  computed: {
    ...mapState(['tokenStatus'])
  },
  methods: {
    jump (str) {
      this.$store.state.navTitle = str;
      this.$router.push('/infoPage')
    },
    loginOut () {

      Dialog.confirm({
        title: '提示',
        message: '确认退出?',
      })
        .then(() => {
          sessionStorage.removeItem('user-token')
          sessionStorage.clear()
          this.$router.push('/')
          this.$store.state.userId = '';
          this.$store.state.tokenStatus = false;
          window.location.reload()
        })

    }
  }
}
</script>

<style lang="less" scoped>
.logo {
  margin: 50px 0 30px 25px;
  img {
    width: 195px;
    height: 60px;
  }
}

.user {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-bottom: 1px solid #ccc;
}
.name {
  font-size: 18px;
  font-weight: 400;
}
.menu {
  // border-bottom: 1px solid #ccc;
  li {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    padding: 0px 25px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
